import pick from 'lodash/pick'
import helpers from '@/helpers'

export default {
  isCompanyInfoFilled(companyInfo) {
    if (!companyInfo) {
      return false
    }

    // return false if any of the field not filled
    return helpers.isAllFieldsFilled(companyInfo)
  },
  isBillingInfoFilled(billingInfo) {
    if (!billingInfo) {
      return false
    }

    switch (billingInfo.current_payment_method) {
      case 'card':
        return true

      case 'e_invoice':
        return helpers.isAllFieldsFilled(
          pick(billingInfo, [
            'edi_number',
            'edi_process_name',
            'edi_process_address'
          ])
        )

      case 'email_invoice':
        return helpers.isAllFieldsFilled(pick(billingInfo, ['email']))

      case 'paper_invoice':
        return helpers.isAllFieldsFilled(
          pick(billingInfo, ['address', 'city', 'postal_code'])
        )

      default:
        return false
    }
  }
}
